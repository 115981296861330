import { PageProps } from "gatsby";
import React from "react";

import { Faq } from "../components/pages/Faq";

import { snProps } from "../js/utils";

const FaqPage = (props: PageProps) => <Faq {...{ ...snProps, ...props }} />;

export default FaqPage;
