import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

import * as pageStyles from "../global-styles/page.module.css";
import { NextPageLink } from "../parts/NextPageLink";

export const Faq = (props: PageComponentProps) => {
  const { clairnoteName, otherClairnoteName, sn, dn, urlDir } = props;
  const title = "FAQ";
  const description = `Frequently asked questions and basic background information about ${clairnoteName} music notation and the Clairnote.org website.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <h2 className="title is-5">Frequently Asked Questions</h2>
        <div>
          <ul>
            <li>
              <a href="#what">What is {clairnoteName}?</a>
            </li>
            <li>
              <a href="#contact">How do I contact you?</a>
            </li>
            {dn && (
              <li>
                <a href="#notes">
                  Why use solid and hollow notes to help indicate pitch?
                </a>
              </li>
            )}
            <li>
              <a href="#staff">Why are the staff lines a major third apart?</a>
            </li>
            <li>
              <a href="#familiar">Why use symbols from traditional notation?</a>
            </li>
            <li>
              <a href="#keysignatures">
                Why does {clairnoteName} have its own key signatures, accidental
                signs, and clefs?
              </a>
            </li>
            {sn && (
              <li>
                <a href="#pitch-pattern">
                  Does {clairnoteName} have a 6-6 pitch pattern?
                </a>
              </li>
            )}
            <li>
              <a href="#newsletter">
                Is there an email newsletter for {clairnoteName}?
              </a>
            </li>
            <li>
              <a href="#group">
                Is there an online forum, group, or email list for{" "}
                {clairnoteName}?
              </a>
            </li>
            <li>
              <a href="#creativecommons">
                What is the copyright status of {clairnoteName} and the
                clairnote.org website?
              </a>
            </li>
            <li>
              <a href="#website">
                Who gets credit for the {clairnoteName} website?
              </a>
            </li>
          </ul>{" "}
          <h2 className="title is-5" id="what">
            What is {clairnoteName}?
          </h2>
          <p>
            {clairnoteName} is an alternative music notation system designed and
            introduced by Paul Morris in{" "}
            {dn ? (
              "December 2013."
            ) : (
              <>
                October 2017. (It is a variant of the {otherClairnoteName} music
                notation system, also designed by Paul Morris, and introduced in
                December 2013.)
              </>
            )}{" "}
            {clairnoteName} makes music easier to read and learn while still
            conveying all of the same information conveyed by traditional
            notation.
          </p>
          <h2 className="title is-5" id="contact">
            How do I contact you?
          </h2>
          <p>
            See the <Link to={urlDir + "contact/"}>Contact Us</Link> page.
          </p>
          {dn && (
            <>
              <h2 className="title is-5" id="notes">
                Why use solid and hollow notes to help indicate pitch?
              </h2>
              <p>
                {clairnoteName} uses solid and hollow noteheads to help
                represent pitch. Solid notes represent one of the two whole tone
                scales and hollow notes represent the other. This alternating
                binary{" "}
                <Link title="6-6 pitch pattern" to={urlDir + "staff/#six-six"}>
                  "6-6" pitch pattern
                </Link>{" "}
                makes it easy to identify notes and more importantly, to clearly
                see the intervals between them.
              </p>
            </>
          )}
          <h2 className="title is-5" id="staff">
            Why are the staff lines a major third apart?
          </h2>
          <p>
            The staff lines in {clairnoteName} are spaced a major third apart,
            which is similar to traditional notation where lines are either a
            major or minor third apart. This is an optimal distance for making
            intervals clear, not too close or too far apart. If lines were
            closer together, say a whole step apart, then larger intervals would
            become harder to recognize since there would be a lot of lines
            between the notes. If lines were further apart you would face the
            opposite problem because there would not be enough lines between the
            notes to visually judge the distance between them. Having lines a
            major third apart provides an optimal distance between these two
            extremes, in addition to being similar to the distance between lines
            in traditional notation. Additionally, a major third is a multiple
            of a whole step (whole step = 2 semitones, major third = 4
            semitones), so the staff aligns with the two whole tone scales and
            complements the{" "}
            <Link to={urlDir + "staff/#six-six"}>6-6 pitch pattern</Link>{" "}
            {dn
              ? "found in the noteheads."
              : "found in the lines and spaces of the staff."}
          </p>
          <h2 className="title is-5" id="familiar">
            Why use symbols from traditional notation?
          </h2>
          <p>
            {clairnoteName} uses most of the same symbols that are used in
            traditional notation to maintain a helpful degree of continuity with
            it. The goal is to make it easier for musicians to learn and to read
            both systems interchangeably. Traditional symbols for rests, time
            signatures, articulation marks, dynamics, ties, slurs, ornaments,
            and note names are all used in {clairnoteName} and retain their
            standard meaning.{" "}
            {dn
              ? "Duration symbols such as flags, stems, and dotted notes also " +
                "have the same meaning as in traditional notation. The exception is that " +
                clairnoteName +
                " uses solid and hollow noteheads to help represent pitch, " +
                "and so half notes are distinguished from quarter notes by having a double stem."
              : "All of the duration symbols such as noteheads, flags, stems, " +
                "and dotted notes, also have the same meaning as in traditional notation."}
          </p>
          <h2 className="title is-5" id="keysignatures">
            Why does {clairnoteName} have its own system of key signatures,
            (optional) accidental signs, and clefs?
          </h2>
          <p>
            {clairnoteName}
            's key signatures, (optional) accidental signs, and clefs provide
            the same information found in traditional notation, but in a clearer
            and more direct way. For instance, {clairnoteName}
            's key signatures and accidental signs distinguish between notes
            like G# and Ab (notes that are enharmonically equivalent in 12-tone
            equal temperament). Clef symbols in {clairnoteName} only indicate
            the octave register of the staff. (They do <em>not</em> change which
            notes are represented by the lines and spaces of the staff, as in
            traditional notation.)
          </p>
          {sn && (
            <>
              <h2 className="title is-5" id="pitch-pattern">
                Does Clairnote SN have a 6-6 pitch pattern?
              </h2>
              <p>
                Yes. Line notes represent one of the two whole tone scales and
                space notes represent the other. This alternating binary{" "}
                <Link title="6-6 Pitch Pattern" to={urlDir + "staff/#six-six"}>
                  "6-6" pitch pattern
                </Link>{" "}
                makes it easier to identify notes and to clearly see the
                intervals between them.
              </p>
            </>
          )}
          <h2 className="title is-5" id="newsletter">
            Is there an email newsletter for {clairnoteName}?
          </h2>
          <p>
            Yes! {/* TODO: change URL */}
            <Link
              to={withPrefix("blog/follow-by-email")}
              title="Subscribe to Newsletter"
            >
              Subscribe to our newsletter
            </Link>{" "}
            to receive occasional news and updates about {clairnoteName}. You
            can also follow our{" "}
            <a title="Blog" href="http://clairnote.org/blog/">
              blog
            </a>{" "}
            by{" "}
            <Link type="application/rss+xml" to={withPrefix("rss.xml")}>
              RSS feed
            </Link>{" "}
            using your favorite feed reader.
          </p>
          <h2 className="title is-5" id="group">
            Is there an online forum, group, or email list for {clairnoteName}?
          </h2>
          <p>
            Yes! Join the{" "}
            <a
              href="https://groups.google.com/g/clairnote"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Clairnote Group
            </a>{" "}
            (on Google Groups). Participate via email or through the website.
            You may also be interested in the{" "}
            <a
              href="http://groups.google.com/group/musicnotation"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Music Notation Project's Google Group
            </a>{" "}
            for general discussion of alternative music notation systems.
          </p>
          <h2 className="title is-5" id="creativecommons">
            What is the copyright status of {clairnoteName} music notation and
            the clairnote.org website?
          </h2>
          <p>
            {clairnoteName} music notation and except where otherwise noted the
            content of the clairnote.org website are the work of Paul Morris and
            are copyright © (2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020,
            2021, 2022). Paul Morris. Both the {clairnoteName} music notation
            system and except where otherwise noted the content of the
            clairnote.org website are licensed under a{" "}
            <a
              href="http://creativecommons.org/licenses/by-sa/4.0/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Creative Commons Attribution-ShareAlike 4.0 International License
            </a>
            . Permissions beyond the scope of this license may be available by
            request.
          </p>
          <h2 className="title is-5" id="website">
            Who gets credit for the clairnote.org website?
          </h2>
          <p>
            This website is built by Paul Morris using{" "}
            <a
              title="Gatsby.js"
              href="https://gatsbyjs.org"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Gatsby
            </a>{" "}
            and{" "}
            <a
              title="Bulma"
              href="https://bulma.io"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Bulma
            </a>
            . Illustrations of {clairnoteName} were created using{" "}
            <Link title="Software: LilyPond" to={urlDir + "software/"}>
              LilyPond
            </Link>{" "}
            and occasionally{" "}
            <a
              href="http://inkscape.org/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Inkscape
            </a>
            . Thanks to{" "}
            <a
              href="https://developer.mozilla.org/en-US/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              MDN
            </a>{" "}
            for documenting web technologies.
          </p>
          <NextPageLink
            title={`${clairnoteName} and ${otherClairnoteName}`}
            to={props.urlDir + "clairnote-dn-clairnote-sn/"}
          />
        </div>
      </article>
    </Layout>
  );
};
